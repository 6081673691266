var exports = {};
exports.__esModule = true;
exports.default = void 0;

const memoOne = (fn, areEqual) => {
  const equal = areEqual || defaultAreEqual;
  let args, value;
  return function () {
    return !!args && equal(arguments, args) ? value : value = fn.apply(null, args = arguments);
  };
};

var _default = memoOne;
exports.default = _default;

const defaultAreEqual = (current, prev) => current[0] === prev[0] && current[1] === prev[1] && current[2] === prev[2] && current[3] === prev[3];

export default exports;
export const __esModule = exports.__esModule;